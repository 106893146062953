.swiper-slide {
  opacity: 1;
}

.swiper-slide-next {
  opacity: 0.5;
}

@media screen and (max-width: 58em) {
  .swiper-slide-next {
    opacity: 1;
  }
}

.swiper-button-disabled {
  transform: scale(0);
}

.swiper-button-next,
.swiper-button-prev {
  background: rgba(9, 10, 11, 0.48);
  --swiper-navigation-color: white;
  border-radius: 8px;
  width: 24px !important;
  height: 24px !important;
}

.swiper-button-next:after {
  font-size: 14px !important;
  content: "\2192" !important;
}

.swiper-button-prev:after {
  font-size: 14px !important;
  content: "\2190" !important;
}
/* .slider-frame {
  padding-bottom: 12px;
  padding-top: 12px;
} */
