.custom-select .select-interface-option {
  --height: 32px;

  --min-height: 32px;

  --color: var(--chakra-colors-contentPrimary);
  border-radius: 6px;
  font-size: 16px;
  --background-hover: var(--chakra-colors-backgroundAccent);
  --background-focused-opacity: 1;
  --background-hover-opacity: 1;
  --color-focused: var(--chakra-colors-backgroundPrimary);
  --background-focused: var(--chakra-colors-backgroundAccent);
  --background: var(--chakra-colors-backgroundPrimary);
}

.custom-select .select-interface-option:hover {
  --color: var(--chakra-colors-backgroundPrimary);
  --background: var(--chakra-colors-backgroundAccent);
}

.custom-select .list-md {
  padding: 0;
  border-radius: 6px;
}
.custom-select .ion-focused {
  --background-focused: var(--chakra-colors-backgroundAccent);
  --color-focused: var(--chakra-colors-backgroundPrimary);
}
.custom-select .ion-focused:hover {
  --background-focused: var(--chakra-colors-backgroundAccent);
  --color-focused: var(--chakra-colors-backgroundPrimary);
}
